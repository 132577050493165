import { deleteRequest, getRequest, postRequest } from '~/utils/api/apiHandler';
import { YkResponse } from '~/models/api';
import { ParsedUrlQuery } from 'querystring';

const paths = {
  getLoginUrl: '/itandi_user_sessions/new',
  create: '/itandi_user_sessions',
  destroy: '/itandi_user_sessions'
};

export const getLoginUrl = (): Promise<YkResponse<{ url: string }>> =>
  getRequest(paths.getLoginUrl);

export const create = (
  params: DeepReadonly<ParsedUrlQuery>
): Promise<YkResponse<void>> => postRequest(paths.create, params);

export const destroy = (): Promise<YkResponse<{ url: string }>> =>
  deleteRequest(paths.destroy);
