import React from 'react';
import { AppProps } from 'next/app';
import { NextPage } from 'next';
import '../styles/globals.scss';
import { SWRConfig } from 'swr';
import { ApiClient } from '~/utils/api/apiClient';
import { AxiosResponse } from 'axios';
import { ToastContainer } from 'react-toastify';

// TODO: itandi-bb-uiで対応したら削除する
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const App: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => (
  <SWRConfig
    value={{
      fetcher: (url: string): Promise<{ data: unknown }> =>
        ApiClient(url).then(
          (r: DeepReadonly<AxiosResponse<{ data: unknown }>>) => r.data
        ),
      revalidateOnFocus: false
    }}
  >
    <ToastContainer />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Component {...pageProps} />
  </SWRConfig>
);

export default App;
