import { YkResponse, YkResponseFail, YkResponseSuccess } from '~/models/api';
import axios, { AxiosResponse } from 'axios';
import { ApiClient } from '~/utils/api/apiClient';

const isYkErrorObject = (error: unknown): error is { message: string } => {
  if (error == null || typeof error !== 'object') {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const hasMessageError = error as Record<keyof { message: string }, unknown>;
  return typeof hasMessageError.message === 'string';
};

export const isSuccess = (
  response: DeepReadonly<YkResponse<unknown, unknown>>
): response is YkResponseSuccess<unknown> => response.type === 'success';

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const parseSuccess = <T>(response: AxiosResponse<T>): YkResponseSuccess<T> => ({
  type: 'success',
  code: response.status ?? 204,
  data: response.data ?? null
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseError = <E>(err: unknown): YkResponseFail<E | any> => {
  if (axios.isAxiosError(err)) {
    const message = isYkErrorObject(err.response?.data)
      ? err.response?.data.message
      : 'エラーが発生しました';
    // TODO: 任意のエラーオブジェクトを返せたほうが良い？
    return {
      type: 'fail',
      code: err.response?.status ?? 500,
      message: message ?? 'エラーが発生しました',
      data: err.response?.data ?? null
    };
  }
  throw err;
};

export const getRequest = async <T, E>(
  path: string,
  params?: DeepReadonly<Record<string, unknown>>
): Promise<YkResponse<T, E>> => {
  try {
    return parseSuccess<T>(await ApiClient.get(path, { params }));
  } catch (err) {
    return parseError<E>(err);
  }
};

export const postRequest = async <T, E>(
  path: string,
  params?: DeepReadonly<Record<string, unknown> | FormData>
): Promise<YkResponse<T, E>> => {
  try {
    return parseSuccess<T>(await ApiClient.post(path, params));
  } catch (err) {
    return parseError<E>(err);
  }
};

export const putRequest = async <T, E>(
  path: string,
  params?: DeepReadonly<Record<string, unknown>>
): Promise<YkResponse<T, E>> => {
  try {
    return parseSuccess<T>(await ApiClient.put(path, params));
  } catch (err) {
    return parseError<E>(err);
  }
};

export const deleteRequest = async <T, E>(
  path: string,
  params?: DeepReadonly<Record<string, unknown>>
): Promise<YkResponse<T, E>> => {
  try {
    return parseSuccess<T>(await ApiClient.delete(path, { params }));
  } catch (err) {
    return parseError<E>(err);
  }
};
