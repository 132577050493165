import Axios, { AxiosError, AxiosResponse } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { getLoginUrl } from '~/utils/api/resources/itandiAccountSession';
import { getLoginUrl as getStaffLoginUrl } from '~/utils/api/resources/itandiUserSession';
import { isSuccess } from '~/utils/api/apiHandler';
import { YkResponse } from '~/models/api';

export const getApiHost = (): string => {
  if (typeof window === 'undefined') {
    return '';
  }
  switch (window.location.hostname) {
    case 'localhost':
      return 'http://localhost:8080';
    case 'stg.yanushi-kanrikun.itandibb.com':
      return 'https://api.stg.yanushi-kanrikun.itandibb.com';
    case 'yanushi-kanrikun.itandibb.com':
      return 'https://api.yanushi-kanrikun.itandibb.com';
    default:
      throw new Error('Unknown host');
  }
};

export const ApiClient = applyCaseMiddleware(
  Axios.create({
    baseURL: `${getApiHost()}/yk`,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true
  }),
  { ignoreHeaders: true }
);

type AuthenticationError = Readonly<{
  code: 'company' | 'staff';
}>;
type ErrorResponse = AuthenticationError | null;

ApiClient.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  (response: AxiosResponse<unknown>): AxiosResponse<unknown> => response,
  (error: DeepReadonly<AxiosError<ErrorResponse>>): Promise<never> => {
    // internal server error（または予期せぬエラー）
    if (!error.response || error.response?.status >= 500) {
      // window.location.href = SERVER_ERROR_PATH;
    }
    // unauthorized
    if (error.response?.status === 401) {
      if (error.response?.data?.code === 'staff') {
        // 要担当者ログイン
        getStaffLoginUrl().then(
          (response: DeepReadonly<YkResponse<{ url: string }>>) => {
            if (isSuccess(response) && response.data?.url != null) {
              window.location.replace(response.data.url);
            } else {
              window.location.replace('/500'); // TODO: エラーページ用意する
            }
          }
        );
      } else if (error.response?.data?.code === 'company') {
        // 要イタンジアカウントログイン
        getLoginUrl().then(
          (response: DeepReadonly<YkResponse<{ url: string }>>) => {
            if (isSuccess(response) && response.data?.url != null) {
              window.location.replace(response.data.url);
            } else {
              window.location.replace('/500'); // TODO: エラーページ用意する
            }
          }
        );
      }
    }
    return Promise.reject(error);
  }
);
